// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDI2w4FkkPn_PSHQsM6sWMOJDuZjLG9aQQ",
  authDomain: "gasbillnew1st.firebaseapp.com",
  databaseURL: "https://gasbillnew1st-default-rtdb.firebaseio.com",
  projectId: "gasbillnew1st",
  storageBucket: "gasbillnew1st.appspot.com",
  messagingSenderId: "338108121248",
  appId: "1:338108121248:web:17b8e66d1d3a7b828949c5",
  measurementId: "G-ET2YX68GH6"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };